import './bootstrap';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
import sort from '@alpinejs/sort'
Alpine.plugin(sort)
import './spinner.js';
import './scorings.js';
import.meta.glob([
    '../images/**',
]);
import 'flowbite';
